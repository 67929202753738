import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ScreenConfigProps, ScreenDisplay, ScreenProps } from './Components/ScreenDisplay/ScreenDisplay';
import { Filter } from './Components/ScreenDisplay/Filter';

function App() {
  const [screenProps, setScreenProps] = React.useState<ScreenConfigProps>();

  return (
    <div className="product-container">
      <div className="product-container-inner">
        <div className="filter" style={{marginBottom: '20px'}}>
          
          <Filter
            onSave={(updateProps: ScreenConfigProps) => setScreenProps(updateProps)}
          />
        </div>
        <div className="product">
          <div className="screen-border">
            <div className="screen-display">
              <ScreenDisplay config={screenProps} />
            </div>
            <div className="screen-light green">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
