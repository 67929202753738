export class CoreRepository{
    private baseUrl: string;

    constructor(){
        this.baseUrl = process.env.REACT_APP_DATA_PLATFORM_URL || 'https://api.gethumbl3.com/api/v1';
    }

    sendRequest(path: string){
        return fetch(this.baseUrl+path)
            .then(response => response.json());
    }
}