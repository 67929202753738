import * as React from 'react';
import { Streams, StreamsConfigProps, StreamsProps } from './Templates/Streams';

export type ScreenConfigProps = {
    streams?: StreamsConfigProps
}

export type ScreenProps = {
    config?: ScreenConfigProps
}

export const ScreenDisplay = (props: ScreenProps): JSX.Element => {
    return <Streams config={props.config?.streams} />;
}
