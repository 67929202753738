import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AudienceRepository } from '../../../Repository/AudienceRepository';
import { Platform } from '../../../Model/Types';
import { CircularProgress } from '@mui/material';

export interface StreamsProps{
    config?: StreamsConfigProps;
}

export interface StreamsConfigProps{
    platform?: Platform;
    trackId?: string;
    artistId?: string;
}

export type StreamsPropsData = {
    h1: number | null;
    h2: string;
    h3?: string;
}

export const Streams = (props: StreamsProps): JSX.Element => {
    const [data, setData] = React.useState<StreamsConfigProps>();
    const [templateData, setTemplateData] = React.useState<StreamsPropsData>();

    const audienceRepository = new AudienceRepository();

    const getIcon = (platform: Platform) => {
        if( platform === Platform.SPOTIFY ){
            return <FontAwesomeIcon icon={faSpotify} />;
        }else if( platform === Platform.YOUTUBE ){
            return <FontAwesomeIcon icon={faYoutube} />;
        }else{
            return <CircularProgress color="success" />;
        }
    }

    const refreshTrackData = (platform: Platform, trackId: string) => {
        audienceRepository.getEstimatedTrackStreams(platform, trackId).then((responseData) => {
            setTemplateData({
                h1: responseData.h1,
                h2: responseData.h2,
                h3: responseData.h3
            });
        });    
    };

    const refreshArtistData = (platform: Platform, artistId: string) => {
        audienceRepository.getEstimatedArtistStreams(platform, artistId).then((responseData) => {
            setTemplateData({
                h1: responseData.h1,
                h2: responseData.h2
            });
        });    
    };

    React.useEffect(() => {
        setData({...props.config});
    }, [props.config]);

    React.useEffect(() => {
        if( data ){
            const interval = setInterval(() => {
                if(data.platform && data.trackId){
                    refreshTrackData(data.platform, data.trackId);
                }else if(data.platform && data.artistId){
                    refreshArtistData(data.platform, data.artistId);
                }
            }, 10000);
            
            return () => clearInterval(interval);
        }

    }, [data]);

    React.useEffect(() => {
        if(data){
            if(data.platform && data.artistId){
                refreshArtistData(data.platform, data.artistId);
            }else if(data.platform && data.trackId){
                refreshTrackData(data.platform, data.trackId);
    
            }
        }
    }, [data]);

    return <>
        <div className="icon">
            {
                ( data && data.platform ) &&
                    <>{getIcon(data.platform)}</>
                   
            }
        </div>
        <div className="body" style={{paddingLeft: '20px'}}>
            {
                templateData && <>
                    <h1>{typeof templateData.h1 === 'number' ? Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale).format(templateData.h1) : 'No Data'}</h1>
                    <h2>{templateData.h2}</h2>
                    <h3>{templateData.h3}</h3>
                </>
            }
        </div>
    </>;
}
