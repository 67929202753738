import { Platform } from "../Model/Types";
import { CoreRepository } from "./CoreRepository";

export class AudienceRepository extends CoreRepository{
    
    searchTracks(keywords: string){
        return this.sendRequest('/audience/search/tracks?q='+encodeURIComponent(keywords));
    }

    searchArtists(keywords: string){
        return this.sendRequest('/audience/search/artists?q='+encodeURIComponent(keywords));
    }

    getTrackStreams(platform: Platform, trackId: string){
        return this.sendRequest('/audience/'+platform+'/tracks/'+trackId+'/total-streams');
    }

    getEstimatedTrackStreams(platform: Platform, trackId: string){
        return this.sendRequest('/audience/'+platform+'/tracks/'+trackId+'/total-estimated-streams');
    }

    getArtistStreams(platform: Platform, artistId: string){
        return this.sendRequest('/audience/'+platform+'/artists/'+artistId+'/total-streams');
    }

    getEstimatedArtistStreams(platform: Platform, artistId: string){
        return this.sendRequest('/audience/'+platform+'/artists/'+artistId+'/total-estimated-streams');
    }
}